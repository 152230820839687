import React, { Component, useEffect } from 'react';
import Select from 'react-select';
import { Alert } from '../../components/Alert';
import axios from 'axios';
import InputPercentage from '../../components/InputPercentage';
import PropTypes from 'prop-types';
import { postVoucher } from '../../actions/ActionVoucher';
import PageClean from '../default/PageClean';
import '../../assets/scss/form.scss';

class Voucher extends Component {
  static propTypes = {
    bIsEditing: PropTypes.bool,
    bIsModal: PropTypes.bool,
    fnHandleClose: PropTypes.func,
    fnHandleConfirm: PropTypes.func,
  };

  static defaultProps = {
    bIsEditing: false,
    bIsModal: false,
    fnHandleClose: evtHandleClose => { },
    fnHandleConfirm: evtHandleConfirm => { },
  };

  constructor(oProps) {
    super(oProps);
    this.state = {
      oAdminVoucher: {
        vch_ccode: 'DESCONTO10',
        vch_ndiscount: 10,
        vch_cdescription: '',
        vch_dexpiration: '',
        vch_ndaysbegin: null
      },
      aAdminUsers: [],
      bLoading: true,
      rcmpAlertMsg: null,
      aInitial: [
        { value: '', label: '' },
      ]
    };
  }

  componentDidMount() {
    axios.get(`admin`)
    .then(oResponse => {
      this.setState({
        aAdminUsers: oResponse.data.aAdminUser,
        bLoading: false
      });
    })
    .catch(oError => {
      this.setState({
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {oError.aMsgErrors}
          </Alert>
        )
      });
    });
  }

  handleChange = (evt, cAttributeName) => {
    const { oAdminVoucher } = this.state;
    if (evt.target.value !== null) {
      oAdminVoucher[cAttributeName] = evt.target.value;
      this.setState({
        oAdminVoucher
      });
      if (this.props.onUpdate) this.props.onUpdate(oAdminVoucher);
    }
  }

  handleChangeSelected = (evt, cAttributeName) => {
    const { oAdminVoucher } = this.state;

    if (evt !== null) {
      oAdminVoucher[cAttributeName] = evt.value;
      this.setState({
        oAdminVoucher
      });
    }
  }

  handleChangeMaskDiscount = (evt) => {
    const { oAdminVoucher } = this.state;
    oAdminVoucher.vch_ndiscount = this.formatPercentage(evt.target.value);
    this.setState({
      oAdminVoucher
    });
  }

  formatPercentage = (input) => {
    // Remove any non-digit characters
    var cleaned = input.replace(/[^\d,]/g, '');
    
    // Split into whole and decimal parts
    var parts = cleaned.split(',');

    // Format whole part (optional, adjust as needed)
    var whole = parts[0] || '';
    whole = whole.slice(0, 2); // Limit to two digits if needed

    // Format decimal part
    var decimal = parts[1] || '';
    if (decimal.length > 2) {
        decimal = decimal.slice(0, 2); // Limit to two decimal places
    }

    // Combine whole and decimal parts with percentage sign
    var formatted = whole + (decimal ? ',' + decimal : '');

    return formatted;
  }

  userCreate = () => {
    const { history } = this.props;
    const { oAdminVoucher } = this.state;

    postVoucher(oAdminVoucher)
      .then(() => {
        history.push({
          pathname: '/Plan',
          state: { aAlertMessages: { cType: 'success', cMsg: 'Voucher criado com sucesso!' } }
        });
      }).catch((oError) => {
       
      });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { setAlert, bIsEditing } = this.props;
    const { oAdminVoucher } = this.state;
    if (bIsEditing) {
      this.userUpdate();
    } else {
      this.userCreate();
    }

  }

  renderAdminVoucher = () => {
    const { oAdminVoucher, aAdminUsers, aInitial } = this.state;
    const { bIsEditing, fnHandleClose, history } = this.props;

    return (
      <form
        className="form"
        onSubmit={this.handleSubmit}
      >
        <input name="_token" type="hidden" value="EvqPygz3uAjVmAdnwafVRC2OEzt0Oyti8qsr1BG9" />
        <fieldset>
          <legend>Obrigatório</legend>
          <label className="split2">
            Código <span className="required">*</span>
              <input maxLength="250" className='uppercase' required name="vch_ccode" type="text" value={oAdminVoucher.vch_ccode} onChange={evt => this.handleChange(evt, 'vch_ccode')} />
          </label>

          <label className="split2 percentage">
            Desconto (%) <span className="required">*</span>
             <InputPercentage
              value={oAdminVoucher.vch_ndiscount || ''}
              inputplaceholder="Desconto (%)"
              maxlength={2}
              inputname="vch_ndiscount"
              onChange={evt => this.handleChangeMaskDiscount(evt)}
              required
            />
          </label>

          <label className="split2 colspan">
            Responsável <span className="required">*</span>

            <Select
              isClearable
              className="selectCustom"
              classNamePrefix="react-select"
              noOptionsMessage={() => 'Requisito não encontrado'}
              placeholder="Selecione"
              options={aAdminUsers.map(oAdminUser => ({ "value": oAdminUser.adm_nid, "label": oAdminUser.adm_cname }))}
              maxMenuHeight={190}
              defaultValue={aInitial}
              onChange={elSelectedOption => this.handleChangeSelected(elSelectedOption, 'adm_nid')}
            />
          </label>

          <label className="split2 percentage">
            Início do contrato (dias)
            <input
              name="vch_ndaysbegin"
              value={oAdminVoucher.vch_ndaysbegin}
              onChange={evt => this.handleChange(evt, 'vch_ndaysbegin')}
              type="number"/>
          </label>

        </fieldset>
        <fieldset>
          <legend>Opcional</legend>
          <label className="split">
            Descrição
            <textarea
              maxLength="250"
              className=""
              name="vch_cdescription"
              value={oAdminVoucher.vch_cdescription}
              onChange={evt => this.handleChange(evt, 'vch_cdescription')}
            />
          </label>

          <label className="split">
            Expira em
              <input
                maxLength="250"
                className=""
                name="vch_dexpiration"
                type="date"
                value={oAdminVoucher.vch_dexpiration}
                onChange={evt => this.handleChange(evt, 'vch_dexpiration')}
            />
          </label>
          <div className="clearfix" />
        </fieldset>

        <div className="button-stack">
          <button type="button" className="btn alternate back btn btn-outline-secondary" title="Cancelar" onClick={(evtClick) => {
            if (bIsEditing) {
              fnHandleClose();
            } else {
              history.push({
                pathname: '/Plan'
              });
            }
          }}>
            Cancelar
            </button>
          {bIsEditing ? (
            <button type="submit" value="salvar" className="btn btn btn-secondary" title="Salvar">
              Salvar
            </button>
          ) : (
            <button type="submit" value="salvar" className="btn btn btn-secondary" title="Criar">
              Criar
            </button>
          )}
        </div>

      </form>
    );
  }

  render() {
    const { bLoading, rcmpAlertMsg } = this.state;
    const { bIsModal } = this.props;

    if (bIsModal) {
      return (
        <PageClean bLoading={bLoading ? 1 : 0}>
          {rcmpAlertMsg}
          {this.renderAdminVoucher()}
        </PageClean>
      );
    }

    return (
      <>
        <main id="container" className="centered">
          <div className="clearfix" />
          {rcmpAlertMsg}
          <h1>Criar voucher</h1>
          {this.renderAdminVoucher()}
          <ul id="bubble-alerts" />
        </main>
      </>
    );
  }
}

export default Voucher;
